<template>
    <div class="virtual-account" @scroll="onScroll">
        <StackRouterHeaderBar :showTitle="showTitle" title="결제 안내" />
        <div class="page-header">
            <div class="title" v-html="'결제 안내'"></div>
        </div>
        <div class="desc" v-html="desc" />
        <div class="body">
            <div class="row">
                <div class="title">은행</div>
                <div class="content">우리은행</div>
            </div>
            <div class="row">
                <div class="title">계좌번호</div>
                <div class="content">{{ userVirtualAccount.virtual_account.account_no }}</div>
            </div>
            <div class="row">
                <div class="title">예금주</div>
                <div class="content">바닐라브릿지</div>
            </div>
            <div class="row">
                <div class="title">입금금액</div>
                <div class="content">{{ userVirtualAccount.amount | currency }} 원</div>
            </div>
            <div class="row">
                <div class="title">입금기한</div>
                <div class="content">{{ userVirtualAccount.valid_until | formatDate('YYYY.MM.DD | HH:mm') }} 까지</div>
            </div>
        </div>
        <div class="bottom-desc">
            <div v-html="'‘확인’을 누르면 더 이상 결제 정보를 확인할 수 없습니다.'" />
        </div>
        <div class="bottom">
            <div class="button" v-html="'확인'" @click="close()" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'VirtualAccountPage',
    components: {},
    props: {
        userVirtualAccount: {
            type: Object,
        },
    },
    data: () => ({
        showTitle: false,
    }),
    computed: {
        desc() {
            if (this.userVirtualAccount.ptype === 'ticket') return '입금이 완료되면, 바로 소개팅권이 지급됩니다!'
            else if (this.userVirtualAccount.ptype === 'premium') return '입금이 완료되면, 바로 프리미엄이 적용됩니다!'
            return ''
        },
    },
    methods: {
        onScroll(event) {
            this.showTitle = event.target.scrollTop > 200
        },
        onClickBackButton() {
            this.$router.go(-1)
        },
        async close() {
            await Promise.all([this.$store.dispatch('loadItems'), this.$store.dispatch('loadMe')])
            this.$stackRouter.clear()
        },
    },
}
</script>

<style lang="scss" scoped>
.virtual-account {
    overflow-y: auto;
    position: relative;
    padding-bottom: 110px;

    hr {
        border-bottom: solid 1px $grey-02;
    }

    .page-header {
        padding: 0 16px;
        margin: 0;
        // margin: 12px 16px 32px;

        .title {
            color: black;
            font-size: 24px;
            line-height: 28px;
            // font-weight: normal;
            // font-family: NotoSans-Regular;
            @include f-medium;
        }
    }

    .desc {
        margin-top: 40px;
        padding: 0 16px;
        font-size: 16px;
        color: $grey-08;
    }

    .body {
        border-radius: 20px;
        border: solid 1px $grey-03;
        margin: 32px 16px 0 16px;
        padding: 28px 24px;
        font-size: 16px;

        .row {
            display: flex;
            flex-direction: row;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            .title {
                color: $grey-06;
                width: 33%;
            }

            .content {
                color: black;
            }
        }
    }

    .bottom-desc {
        position: absolute;
        bottom: 88px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
            font-size: 13px;
            color: $purple-primary;
        }
    }

    .bottom {
        position: absolute;
        bottom: 0;
        padding: 16px;
        width: 100%;
        border-top: solid 1px $grey-02;

        div {
            width: 100%;
            height: 48px;
            border-radius: 8px;
            background-color: $purple-primary;
            @include f-medium;
            font-size: 15px;
            color: white;
            line-height: 48px;
            text-align: center;
        }
    }
}
</style>
